import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { ProductVariantInventoryPolicy } from "../../shopifyGraphQL/api";

export const areAllVariantsOutOfStock = (purchasable: IPurchasable) => {
  if (!purchasable.vnts?.length) {
    return false;
  }
  /**
   * If inventoryPolicy is set to DENY and inventoryQuantity is less
   * than or equal to 0, then variant is out of stock
   */
  return purchasable.vnts.every(vnt => {
    return vnt.inventoryPolicy === ProductVariantInventoryPolicy.Deny && (vnt.inventoryQuantity ?? 0) <= 0;
  });
};

import { BadgeStatusValue } from "@shopify/polaris";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import { ProductApi } from "@smartrr/shared/interfaces/product/api";
import { VariantApi } from "@smartrr/shared/interfaces/variant/api";
import { isVariantInStock } from "@smartrr/shared/utils/variants";
import { areAllVariantsOutOfStock } from "@smartrr/shared/utils/purchasables/index";

export enum BadgeStatusTitle {
  Deleted = "Deleted",
  DraftOrArchived = "Draft / Archived",
  OutOfStock = "Out of stock",
  Active = "Active",
}

const isVariant = (
  obj: IPurchasable | IPurchasableVariant | ProductApi.Product | VariantApi.Variant
): obj is IPurchasableVariant | VariantApi.Variant => {
  return obj.hasOwnProperty("inventoryPolicy");
};

/**
 * Type guard checking if obj is IPurchasable type
 */
const isPurchasable = (
  obj: IPurchasable | IPurchasableVariant | ProductApi.Product | VariantApi.Variant
): obj is IPurchasable => obj.hasOwnProperty("purchasableName");

export const renderProductStatusTitle = (
  obj: IPurchasable | IPurchasableVariant | ProductApi.Product | VariantApi.Variant | undefined
): BadgeStatusTitle | undefined => {
  if (!obj) {
    return undefined;
  }
  if (!obj.isActiveInShopify) {
    return BadgeStatusTitle.Deleted;
  }
  if (obj.isDraftOrArchived) {
    return BadgeStatusTitle.DraftOrArchived;
  }
  if (isVariant(obj) && !isVariantInStock(obj)) {
    return BadgeStatusTitle.OutOfStock;
  }

  if (isPurchasable(obj) && areAllVariantsOutOfStock(obj)) {
    return BadgeStatusTitle.OutOfStock;
  }
  if (obj.isActiveInShopify) {
    return BadgeStatusTitle.Active;
  }
  return undefined;
};

export const renderProductStatus = (
  obj: IPurchasable | IPurchasableVariant | ProductApi.Product | VariantApi.Variant | undefined
): BadgeStatusValue => {
  if (!obj) {
    return BadgeStatusValue.New;
  }

  if (!obj.isActiveInShopify) {
    return BadgeStatusValue.Critical;
  }
  if (obj.isDraftOrArchived) {
    return BadgeStatusValue.New;
  }
  if (isVariant(obj) && !isVariantInStock(obj)) {
    return BadgeStatusValue.Warning;
  }
  if (isPurchasable(obj) && areAllVariantsOutOfStock(obj)) {
    return BadgeStatusValue.Warning;
  }
  if (obj.isActiveInShopify) {
    return BadgeStatusValue.Success;
  }

  return BadgeStatusValue.New;
};
